<template>
	<div id="project">
		<!-- 项目列表 -->
		<div class="hzCont">
			<div class="title">我的项目<i></i> </div>
			<div class="top">
				<div class="tabs">
					<template v-for="(item,i) in list">
						<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
							{{item.name}}
							<i></i>
							<span :class="`num ${item.class}`">{{item.num}}</span>
						</p>
						<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
					</template>
				</div>
			</div>
			<div class="list">
				<!-- 	<h1 v-if="todayMeetList[0]&& false">今日会议</h1>
				<el-carousel height="350px" v-if="todayMeetList[0]&& false" indicator-position="outside">
					<el-carousel-item v-for="item in todayMeetList" :key="item.id">
						<div class="bgImg">
							<img :src="item.bgImage">
							<span></span>
						</div>
						<div class="main">
							<div class="tit">
								会议主题：
								{{item.meetingTitle}}
							</div>
							<div class="pro">
								<img src="../../../assets/imgs/icon1.png">
								所属项目：
								{{item.projectName}}
							</div>
							<div class="time">
								<img src="../../../assets/imgs/icon2.png">
								开始时间：
								{{$moment(item.meetListStartDate).format('L') }}
								{{$moment(item.meetListStartDate).format('LT') }}
							</div>
							<div class="users">
								<img src="../../../assets/imgs/icon3.png">
								参会医生：
								{{item.doctors}}
							</div>
							<el-button class="goLive" @click="enterTheMeeting(item)">进入会议</el-button>
						</div>
					</el-carousel-item>
				</el-carousel> -->

				<h1 v-if="curNow == 'WAIT'">进行中的项目</h1>
				<h1 v-if="curNow == 'FINISH'">已结束的项目</h1>
				<h1 v-if="curNow == 'WAIT_CLAIM'">待认领的项目</h1>
				<div class="item" v-for="item in pList" :key="item.id">
					<div class="tags">
						<div class="type" v-if="item.projectType == 'single'">
							<img src="../../../assets/imgs/video.png">单场会
						</div>
						<div class="type" v-else-if="item.projectType == 'series'">
							<img src="../../../assets/imgs/video2.png">系列会
						</div>
						<div class="type" v-else></div>
						<div class="itemMark">
							<p>编号：{{item.id}}</p>
							<p v-if="item.waitClaimNum*1" class="waitClaim">待认领： <span>{{item.waitClaimNum}}</span>
							</p>
						</div>
					</div>
					<div class="main">
						<div class="left">
							<div class="tit">项目主题：{{item.projectName}}</div>
							<div class="time">
								项目时间：
								{{$moment(item.projectStartDate).format('L') }}
								-
								{{$moment(item.projectEndDate).format('L') }}
							</div>
							<div class="type">
								<span class="WAIT" v-if="item.projectStatus == 'UNDERWAY'">进行中</span>
								<span class="FINISH" v-if="item.projectStatus == 'TIMEOUT'">已结束</span>
								<span class="FINISH" v-if="item.projectStatus == 'WAIT_CLAIM'">待认领</span>
							</div>
						</div>
						<div class="opts">
							<el-button v-if="item.projectStatus == 'UNDERWAY'" class="details"
								@click.stop="toMeetList(item)">进入项目</el-button>
							<el-button v-if="item.projectStatus == 'TIMEOUT'" class="details"
								@click.stop="toMeetList(item)">进入项目</el-button>
							<el-button v-if="item.projectStatus == 'WAIT_CLAIM'" class="WAIT_CLAIM"
								@click.stop="claimMeeting(item)">认领项目</el-button>
							<!-- <el-button class="agreement" v-if="item.projectStatus != 'WAIT_CLAIM'"
								@click.stop="settleAnAccount(item)">回款结算</el-button> -->
						</div>
					</div>
				</div>
				<div class="null" v-if="!pList[0]">
					<img src="../../../assets/imgs/EmptyState.png" alt="">
					<p>没有查询到相关内容</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" class="pagination" @current-change="getMyProject"
				:hide-on-single-page="false"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		oprojectList,
		officialTodayMeeting,
		ogetMeetingUrl,
		claimProject,
		getProjectStatusNum
	} from '@/request/api.js';
	export default {
		name: 'project',
		components: {},
		data() {
			return {
				page: 1,
				size: 5,
				total: 0,
				curNow: "WAIT",
				pList: [],
				todayMeetList: [],
				list: [{
					name: '进行中的项目',
					class: 'WAIT',
				}, {
					name: '待认领的项目',
					class: 'WAIT_CLAIM',
				}, {
					name: '已结束的项目',
					class: 'FINISH',
				}],
				message: ''
			}
		},
		created() {
			// this.getTodayMeeting();
			this.getProjectList();
			this.getProjectItemNums();
		},
		watch: {
			'$store.state.sponsorId': {
				handler(val) {
					// console.log("qrojectList", val)
					setTimeout(() => {
						this.getProjectList();
						this.getProjectItemNums();
					}, 300)
				},
				immediate: true,
				deep: true
			}
		},
		mounted() {},
		methods: {
			getProjectItemNums() {
				let data = {

				};
				getProjectStatusNum().then(res => {
					if (res.code == 200) {
						console.log(res.data)
						this.list.map(item => {
							if (item.class == 'WAIT') item.num = res.data.underwayProjectNum;
							if (item.class == 'FINISH') item.num = res.data.timeoutProjectNum;
							if (item.class == 'WAIT_CLAIM') item.num = res.data.waitProjectNum;
						})
						this.$forceUpdate();
					}
				})
			},

			// 认领会议
			claimMeeting(item) {
				// console.log(JSON.parse(JSON.stringify(item)))
				this.$alert('是否确认认领该项目？', '认领项目', {
					confirmButtonText: '确定',
					callback: action => {
						claimProject({
							projectId: item.id
						}).then(res => {
							if (res.code == 200) {
								this.getProjectList();
								this.$forceUpdate();
							} else {
								this.$message.error(res.msg)
							}
						})
					}
				});
			},

			// 创建会议
			newMeeting(item) {
				localStorage.setItem('projectDetails', JSON.stringify(item))
				this.$router.push({
					name: 'createmeet',
					query: {
						pId: item.id
					}
				})
			},

			// 查看会议详情
			toMeetList(item) {
				localStorage.setItem('projectDetails', JSON.stringify(item))
				this.$router.push({
					name: 'officialMeetinglist',
					query: {
						pId: item.id
					}
				})
			},

			// 回款结算
			settleAnAccount(item) {
				localStorage.setItem('projectDetails', JSON.stringify(item))
				this.$router.push({
					name: 'settleaccount',
					query: {
						pId: item.id
					}
				})
			},

			// 进入会议
			enterTheMeeting(item) {
				// localStorage.setItem('projectDetails', JSON.stringify(item))
				let data = {
					meetingId: item.id
				}
				ogetMeetingUrl(data).then(res => {
					if (res.code == 200) {
						window.open(res.data)
					}
				})
			},

			// 获取今日会议列表
			getTodayMeeting() {
				officialTodayMeeting().then(res => {
					if (res.code == 200) {
						this.todayMeetList = res.data;
					}
				})
			},

			// 获取项目列表
			getProjectList() {
				let data = {
					current: this.page,
					size: this.size,
					projectType: "series"
				}
				if (this.curNow === 'WAIT') data.projectStatus = 'UNDERWAY';
				if (this.curNow === 'FINISH') data.projectStatus = 'TIMEOUT';
				if (this.curNow === 'WAIT_CLAIM') data.projectStatus = 'WAIT_CLAIM';
				oprojectList(data).then(res => {
					if (res.code == 200) {
						this.pList = res.data.records;
						this.total = res.data.total;
					}
				})
			},

			// 分页切换
			getMyProject(e) {
				this.page = e;
				this.getProjectList();
			},

			// 切换会诊选项
			tabsChange(item) {
				this.page = 1;
				this.curNow = item.class;
				this.getProjectList();
				this.getProjectItemNums();
			},
		}
	}
</script>

<style lang="scss" scoped>
	#project {
		display: flex;
		flex-direction: column;

		.top {
			padding: 20px;
			margin-bottom: 20px;
			border-radius: 10px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
		}

		.title {
			font-size: 20px;
			padding: 0 20px 0 30px;
			position: relative;
			margin-bottom: 20px;

			i {
				top: 50%;
				left: 0px;
				width: 20px;
				height: 20px;
				position: absolute;
				border-radius: 50%;
				background-color: #3563E9;
				border: 5px solid #C2D0F8;
				transform: translateY(-50%);
			}
		}

		.hzCont {
			flex: 1;
			padding: 20px 30px;
			display: flex;
			background-color: #fff;
			flex-direction: column;

			.tabs {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;

				.el-divider {
					width: 2px;
					height: 30px;
					margin: 0;
				}

				p {
					width: 200px;
					padding: 0 10px;
					text-align: center;
					font-size: 18px;
					cursor: pointer;
					line-height: 2.5;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						width: 90%;
						left: 50%;
						bottom: 0;
						height: 0;
						background-color: #0065FF;
						position: absolute;
						transition: all .3s;
						transform: translateX(-50%);
					}
				}

				.active i {
					height: 6px;
				}

				.num {
					font-size: 12px;
					color: #fff;
					padding: 0 10px;
					border-radius: 5px;
					margin-left: 5px;
				}

				.WAIT {
					background-color: #0065FF;
				}

				.WAIT_CLAIM {
					background-color: #E6A23C;
				}

				.FINISH {
					background-color: #999;
				}
			}

			.list {
				flex: 1;

				h1 {
					color: #000;
					font-size: 20px;
					margin-bottom: 20px;
				}

				// .el-carousel {
				// 	margin-bottom: 10px;
				// }
				// .el-carousel__item {
				// 	display: flex;
				// 	flex-direction: row;
				// 	.bgImg {
				// 		flex: 1;
				// 		height: 100%;
				// 		overflow: hidden;
				// 		object-fit: cover;
				// 		border-radius: 5px;
				// 		img {
				// 			width: 100%;
				// 			height: 100%;
				// 			display: block;
				// 			object-fit: cover;
				// 		}
				// 	}
				// 	.main {
				// 		flex: 1;
				// 		padding: 20px 0 20px 40px;
				// 		display: flex;
				// 		flex-direction: column;
				// 		align-items: space-between;
				// 		justify-content: space-between;
				// 		>div {
				// 			font-size: 16px;
				// 			display: flex;
				// 			align-items: center;
				// 			color: #112950;
				// 			img {
				// 				height: 16px;
				// 				margin-right: 5px;
				// 			}
				// 		}
				// 		.tit {
				// 			font-size: 22px;
				// 			color: #000;
				// 		}
				// 		.goLive {
				// 			color: #fff;
				// 			height: 50px;
				// 			background-color: #D43030;
				// 		}
				// 	}
				// }
				// .itemToday {
				// 	display: flex;
				// 	margin-bottom: 20px;
				// 	flex-direction: row;
				// 	padding: 20px;
				// 	box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
				// 	transition: all .3s;
				// 	border-radius: 10px;
				// 	align-items: center;
				// 	&:hover {
				// 		background-color: #E8EEFC;
				// 	}
				// 	.main {
				// 		flex: 1;
				// 		display: flex;
				// 		flex-direction: column;
				// 		.tag {
				// 			display: flex;
				// 			margin-bottom: 10px;
				// 			span {
				// 				height: 20px;
				// 				background-color: #007AFF;
				// 				font-size: 12px;
				// 				border-radius: 5px;
				// 				color: #fff;
				// 				display: flex;
				// 				align-items: center;
				// 				padding: 0 10px;
				// 			}
				// 		}
				// 		.tit {
				// 			font-size: 16px;
				// 			color: #000;
				// 			margin-bottom: 10px;
				// 			line-height: 1;
				// 		}
				// 		.time {
				// 			font-size: 13px;
				// 			color: #007AFF;
				// 			line-height: 1;
				// 		}
				// 	}
				// 	.goLive {
				// 		background: #0065FF;
				// 		color: #fff;
				// 		padding: 0 25px;
				// 		height: 28px;
				// 	}
				// }

				.item {
					display: flex;
					margin-bottom: 20px;
					flex-direction: column;
					padding: 20px;
					box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
					transition: all .3s;
					border-radius: 10px;

					&:hover {
						background-color: #E8EEFC;
					}

					.tags {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						margin-bottom: 10px;

						.type {
							display: flex;
							align-items: center;
							color: #000;
							font-size: 12px;

							img {
								margin-right: 5px;
								width: 20px;
								height: 15px;
							}
						}

						.itemMark {
							font-size: 12px;
							color: #999;
							display: flex;
							align-items: center;

							p {
								margin-left: 20px;
								display: flex;
								align-items: center;
							}

							.waitClaim {
								span {
									color: red;
									font-weight: bold;
									font-size: 15px;
								}
							}
						}
					}



					.main {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.left {
							.tit {
								font-size: 16px;
								color: #333;
								margin-bottom: 10px;
								line-height: 1;
								font-weight: bold;
							}

							.time {
								font-size: 13px;
								color: #999;
								line-height: 1;
								margin-bottom: 10px;
							}

							.type {
								display: flex;

								.el-button--text {
									padding: 0;
									font-size: 13px;
								}

								.WAIT {
									color: #007AFF;
								}

								.FINISH {
									color: #999;
								}
							}
						}

						.opts {
							display: flex;
							align-items: flex-end;

							.details {
								background-color: #007AFF;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}

							.agreement {
								// background-color: #007AFF;
								background-color: #D43030;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}

							.WAIT_CLAIM {
								background-color: #E6A23C;
								color: #fff;
								padding: 0 25px;
								height: 28px;
							}
						}
					}
				}

				.null {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					padding: 100px;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
					}
				}
			}

			.el-pagination {
				margin: auto;
			}
		}

	}
</style>